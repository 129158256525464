<template>
    <div v-if="alcohol">
        <h3>Ahuevo padrino, sí tiene pisto</h3>
        <img src="@/assets/pisto.png" alt="Si tiene pisto">
    </div>
    <div v-else>
        <h3>Chale padrino, no tiene pisto :'(</h3>
        <img src="@/assets/no_pisto.png" alt="Sin pisto">
    </div>
</template>

<script>
export default {
    props: {
        alcohol: {
            type: Boolean,
            required: true
        }
    }
}
</script>

<style scoped>
 img {
    margin-top: 0%;
    width: 25%;
    height: 25%;
 }
</style>
<template>
    <img :src="asset" alt="PistoShop">
</template>

<script>
export default {
    props: {
        asset: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped>

img {
    width: 50%;
    height: 50%;
}
</style>
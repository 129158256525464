<template>
  <h1 class="titulo">Pisto Shop</h1>
  <TitlePisto :name="name"/>
  <!--<DetallesPisto :data="pistoData"/>-->
  <Ingredients :pistoData="pistoData" :instructions="instructions"/>
  <br>
  <ImagePisto :asset="fotito"/>
  <Alcohol :alcohol="alcohol"/>
  <br>
  <button @click="setPisto">Aber otro</button>
</template>

<script>
import TitlePisto from "@/components/TitlePisto.vue"
import DetallesPisto from "@/components/DetallesPisto.vue"
import Ingredients from "@/components/Ingredients.vue";
import ImagePisto from "@/components/ImagePisto.vue";
import Alcohol from "@/components/Alcohol.vue";

import getPistito from '@/helpers/getPisto';

export default {
  components: {
    TitlePisto,
    DetallesPisto,
    Ingredients,
    ImagePisto,
    Alcohol
},
  data() {
    return {
      name: "",
      pistoData: [],
      measureData: [],
      instructions: "",
      fotito: "",
      alcohol: false
    }
  },
  methods: {
  async setPisto() {
      const res = await getPistito()
      const data = Object.entries(res)
      this.name = res.strDrink
      this.instructions = res.strInstructions
      this.fotito = res.strDrinkThumb
      this.alcohol = res.strAlcoholic === "Alcoholic"

      const _ingredients = data.slice(17, 31).filter(e => {
        return e[1] !== null
      })

      const _measure = data.slice(32, 46).filter(e => {
        return e[1] !== null
      }) 

      var resultado = await []

      _ingredients.forEach((e, index) => {
        resultado.push([_ingredients[index][1], _measure[index][1]])
      })

      this.pistoData = resultado
    }
  },
  mounted() {
    this.setPisto()     
  }
}
</script>

<style scoped>
h1 {
  color: #FFC947
}

button {
  top: auto;
}

</style>
<template>
  <h3>Detalles pisto</h3>
</template>

<script>

export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    }
}
</script>

<style>

</style>
<template>
  <table>
  <thead>
    <tr>
      <th>Ingredient</th>
      <th>Measure</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="(item, index) in pistoData" :key="index">
      <td data-label="Ingredient">{{ item[0] }}</td>
      <td data-label="Measure">{{ item[1] }}</td>
    </tr>
  </tbody>

</table>
<br>
<h4>Instructions</h4>
<p>{{ instructions }}</p>
</template>

<script>
export default {
    props: {
        pistoData: {
            type: Array,
            required: true
        },
        instructions: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped>
p {
    margin: auto;
    width: 50%;
}
</style>
<template>
  <h2>{{ name }}</h2>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
h2 {
  color: #47B5FF
}
</style>